.layout-main-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    justify-content: space-between;
    padding-top: 7rem;
    padding-bottom: 2rem;
    transition: margin-left $transitionDuration;
}

.layout-main {
    flex: 1 1 auto;
}
