.max-w-screen-sm {
    max-width: 640px;
}
.max-w-screen-md {
    max-width: 768px;
}
.max-w-screen-lg {
    max-width: 1024px;
}
.max-w-screen-xl {
    max-width: 1280px;
}
.max-w-screen-2xl {
    max-width: 1536px;
}

.grid-span-1-2 {
    grid-column: 1/2;
}
.grid-span-2-3 {
    grid-column: 2/3;
}
.grid-span-1-3 {
    grid-column: 1/3;
}
.md-grid-span-1-2 {
    @media screen and (min-width: 768px) {
        grid-column: 1/2;
    }
}
.md-grid-span-2-3 {
    @media screen and (min-width: 768px) {
        grid-column: 2/3;
    }
}
.md-grid-span-1-3 {
    @media screen and (min-width: 768px) {
        grid-column: 1/3;
    }
}
.lg-grid-span-1-2 {
    @media screen and (min-width: 1024px) {
        grid-column: 1/2;
    }
}
.lg-grid-span-2-3 {
    @media screen and (min-width: 1024px) {
        grid-column: 2/3;
    }
}
.lg-grid-span-1-3 {
    @media screen and (min-width: 1024px) {
        grid-column: 1/3;
    }
}

input.input-rounded {
    border-radius: 10rem !important;
    height: unset !important;
}

p-dropdown.dropdown-rounded {
    & > div {
        border-radius: 10rem;
        height: unset !important;
    }
}
p-multiSelect.multi-select-rounded {
    & > div {
        border-radius: 10rem;
        height: unset !important;
    }
}

.field-margin-none {
    .field {
        margin: 0;
    }
}

.p-datatable-tr-no-outline tr:focus {
    outline: none !important;
}
.p-datatable-tr-bg-transition-sm tr:not(.p-highlight) {
    transition: background-color 0.1s ease;
}
