@import '~@fortawesome/fontawesome-free/css/all.min.css';
@import '~primeng/resources/primeng.min.css';
@import '~primeflex/primeflex.css';

@import 'assets/styles/badges.scss';
@import 'assets/styles/code.scss';
@import 'assets/styles/layout.scss';
@import 'assets/theme/theme.css';
@import 'assets/theme/utilities.scss';
@import 'assets/theme/print.scss';

:root {
    --var-topbar-height: 70px;
    --var-footer-height: 4rem;
    --layout-top-reserved-space: 98px;
    --layout-bottom-reserved-space: 28px;
}

:root {
    --var-content-height: calc(
        100vh - var(--layout-top-reserved-space, 70px) - var(--layout-bottom-reserved-space, 28px) - var(
                --var-footer-height,
                4rem
            )
    );
}

.pi.chevron-up::before {
    content: '\f077';
    font-family: FontAwesome;
}

.pi-chevron-right::before {
    content: '\f054';
    font-family: FontAwesome;
}

.pi-chevron-down::before {
    content: '\f078';
    font-family: FontAwesome;
}

.pi-chevron-left::before {
    content: '\f053';
    font-family: FontAwesome;
}

.pi-angle-up::before {
    content: '\f106';
    font-family: FontAwesome;
}

.pi-angle-right::before {
    content: '\f105';
    font-family: FontAwesome;
}

.pi-angle-down::before {
    content: '\f107';
    font-family: FontAwesome;
}

.pi-angle-left::before {
    content: '\f104';
    font-family: FontAwesome;
}

.pi-angle-double-up::before {
    content: '\f102';
    font-family: FontAwesome;
}

.pi-angle-double-right::before {
    content: '\f101';
    font-family: FontAwesome;
}

.pi-angle-double-down::before {
    content: '\f103';
    font-family: FontAwesome;
}

.pi-angle-double-left::before {
    content: '\f100';
    font-family: FontAwesome;
}

.pi-sort-alt::before {
    content: '\f0dc';
    font-family: FontAwesome;
    font-style: normal;
}

.pi-times-circle::before {
    content: '\f057';
    font-family: FontAwesome;
    font-style: normal;
}

.p-dropdown-clear-icon::before,
.p-multiselect-close-icon::before {
    content: '\f00d';
    font-family: FontAwesome;
    font-style: normal;
}

.pi-plus::before {
    font-family: FontAwesome;
    content: '\2b';
}

.pi-minus::before {
    content: '\f068';
    font-family: FontAwesome;
}

.pi-eye::before {
    content: '\f06e';
    font-family: FontAwesome;
}

.pi-eye-slash::before {
    content: '\f070';
    font-family: FontAwesome;
}

.pi-filter::before {
    content: '\f0b0';
    font-family: FontAwesome;
}

.pi-sort-amount-up-alt::before {
    content: '\f885';
    font-family: FontAwesome;
    font-style: normal;
}

.pi-sort-amount-down::before {
    content: '\f161';
    font-family: FontAwesome;
    font-style: normal;
}

.p-icon pi-filter-icon::before {
    content: '\f0b0';
    font-family: FontAwesome;
    font-style: normal;
}

.p-column-filter-clear-button::before {
    content: '\e17b';
    font-family: FontAwesome;
    font-style: normal;
}

.p-button.p-button-xs {
    font-size: 0.581rem;
    padding: 0.394rem 0.581rem;
}

.p-button.p-button-xs .p-button-icon {
    font-size: 0.581rem;
}

.p-splitbutton.p-button-xs > .p-button {
    font-size: 0.581rem;
    padding: 0.394rem 0.581rem;
}

.p-splitbutton.p-button-xs > .p-button .p-button-icon {
    font-size: 0.581rem;
}

.p-input-icon-left > i:first-of-type {
    top: 44%;
}

eyeicon,
eyeslashicon {
    right: 10px;
}

#language-dropdown {
    border: none;
}

#language-dropdown > .p-inputtext,
.p-togglebutton,
.p-selectbutton,
.p-inputgroup {
    box-shadow: none;
}

.border-top-12 {
    border-top-width: 12px !important;
    border-top-style: solid;
}

.border-green-sf {
    border-color: var(--green-sf) !important;
}

.text-green-sf {
    color: var(--green-sf) !important;
}
.bg-green-sf {
    background-color: var(--green-sf) !important;
}
.layout-main:has(app-new-first-customer) {
    width: unset;
    margin: unset !important;
}

.layout-main-container:has(app-new-first-customer) {
    width: unset;
    padding: unset;
}
